<!-- Created by henian.xu on 2018/8/9. -->

<template>
  <div class="shop-header">
    <div class="bg">
      <div 
        class="inner" 
        :style="`background-image: url('${storeInfo.storeAdImageUrl||$defaultImg}');`"
      />
    </div>
    <div class="content">
      <div 
        class="logo" 
        :style="`background-image: url('${storeInfo.storeLogoUrl||$defaultImg}');`"
      />
      <div class="name">
        {{ storeInfo.storeName }}
      </div>
      <div class="desc">
        <div class="add">
          <i class="f-icon">&#xf00a;</i> {{ storeInfo.storeDetailAddress }} {{ storeInfo.storeRegionName }}
        </div>
      </div>
      <!--<div class="desc">
                <a :href="`tel:${storeInfo.contactTel}`"
                   class="item tc-blue"
                ><i class="f-icon tc-blue">&#xf026;</i> {{storeInfo.contactTel}}</a>
                <div class="item"><i class="f-icon">&#xf027;</i> {{storeInfo.openTime}}</div>
                <div class="add"><i class="f-icon">&#xf00a;</i> {{storeInfo.storeDetailAddress}} {{storeInfo.storeRegionName}}</div>
                <div class="notice"><i class="f-icon">&#xf009;</i> {{storeInfo.storeNotice}}</div>
            </div>-->
      <div 
        class="info" 
        v-if="isExtend"
      >
        <!--<div class="fw-bold fs-big">公告</div>-->
        <a 
          :href="`tel:${storeInfo.contactTel}`"
          class="item"
        ><i class="f-icon">&#xf026;</i> {{ storeInfo.contactTel }}</a>
        <div class="item">
          <i class="f-icon">&#xf027;</i> {{ storeInfo.openTime }}
        </div>
      </div>
      <div 
        class="notice" 
        v-if="isExtend"
      >
        <div class="fw-bold fs-big">
          公告
        </div>
        <div class="content">
          {{ storeInfo.storeNotice }}
        </div>
      </div>
      <div 
        class="ta-c tc-g6"
        @click="isExtend=!isExtend"
      >
        <i 
          class="f-icon" 
          v-if="isExtend"
        >&#xf00e;</i>
        <i 
          class="f-icon" 
          v-else
        >&#xf010;</i>
      </div>
    </div>
    <!--<pre>{{storeInfo}}</pre>-->
  </div>
</template>

<script>
import storeMixin from './storeMixin';

export default {
  name: 'ShopHeader',
  mixins: [storeMixin],
  data() {
    return {
      isExtend: false,
    };
  },
};
</script>

<style lang="scss">
.shop-header {
  background-color: #fff;
  > .bg {
    > .inner {
      width: 100%;
      height: 0;
      padding-top: 30%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  > .content {
    $logoSize: 1.8rem !default;
    position: relative;
    padding: $logoSize / 2 $padding $padding $padding;

    > .logo {
      position: absolute;
      top: $logoSize / -2;
      left: 50%;
      margin-top: 0;
      margin-left: $logoSize / -2;
      width: $logoSize;
      height: $logoSize;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 0.1rem;
      box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.2);
    }
    > .name {
      text-align: center;
      font-size: 0.36rem;
      font-weight: bold;
      margin-top: $margin-small;
    }
    > .desc {
      text-align: center;
      font-size: 0.26rem;
      color: $gray7;
    }
    > .info {
      font-size: 0.22rem;
      text-align: center;
      > .item {
        display: inline-block;
        border: 1px solid $color-main;
        padding: 0 $padding-small;
        border-radius: 0.1rem;
        color: $color-main;
      }
    }
    > .notice {
      margin-top: $margin;
    }
  }
}
</style>
