<!-- Created by henian.xu on 2017/10/22. -->
<template>
  <Page>
    <container ref="container">
      <address-picker
        class="bc-w"
        is-simple
        ref="addressPicker"
        v-model="DeliveryAddress"
      />
      <list>
        <shop-item
          v-for="(item) in shopList"
          :key="item.id"
          is-goto
          :data="item"
          @click="onShopItem(item)"
        />
      </list>
      <div
        class="ta-c pa-a"
        v-if="!location&&!locationed"
      >
        定位中...
      </div>
    </container>
  </Page>
</template>

<script>
import pageMixin from '@/mixins/layout/page';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'NearbyStore',
  mixins: [pageMixin],
  data() {
    return {
      DeliveryAddress: {},
      shopList: [],
      locationed: false,
    };
  },
  computed: {
    ...mapState('global', ['location']),
  },
  watch: {
    'DeliveryAddress.id': {
      handler(val) {
        if (!val) return;
        this.getStoreList(val);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('global', ['getLocation']),
    getStoreList(id = this.DeliveryAddress.id) {
      if (!id) return;
      this.$api.Buyer.Sp.ShopStore.findNearbyStoreList({ id }).then(json => {
        this.shopList = json.data.data;
      });
    },
    async getDeliveryAddressId() {
      let location = this.location;
      if (!location) {
        location = await this.getLocation();
        console.log(location);
      }
      return new Promise((resolve, reject) => {
        if (!location) {
          this.$messageBox.alert('定位失败,请选择地址', '温馨提示').then(() => {
            reject(new Error('没有当前坐标'));
          });
          return;
        }
        this.$api.Buyer.Mb.BuyerDeliveryAddress.findNearbyAddress(
          location,
        ).then(json => {
          const res = json.data;
          if (!res.success) {
            this.$messageBox.alert(
              `<div class="ta-c">${res.msg}<br>请选择地址</div>`,
              '温馨提示',
            );
            return reject(new Error('当前坐标没有可用的收货地址'));
          }
          return resolve(res.data);
        });
      });
    },
    onShopItem(item) {
      this.$router.push(`/store/${item.id}/home`);
    },
  },
  created() {
    this.getDeliveryAddressId()
      .then(data => {
        this.locationed = true;
        this.DeliveryAddress = data;
      })
      .catch(() => {
        this.locationed = true;
        this.$refs.addressPicker.open();
      });
  },
};
</script>

<style lang="scss">
</style>
