<!-- Created by henian.xu on 2017/10/22. -->
<template>
  <Page>
    <tabs
      class="average"
      @tabChange="onTabsChange"
    >
      <tab-panel
        label="全部"
        :sign="0"
        :active-panel="+shipMethod === 0"
      />
      <tab-panel
        label="送货上门"
        :sign="1"
        :active-panel="+shipMethod === 1"
      />
      <tab-panel
        label="门店自取"
        :sign="2"
        :active-panel="+shipMethod === 2"
      />
    </tabs>
    <container ref="container">
      <List>
        <StoreGoodsStatItem
          v-for="(item) in statList"
          :key="item.id"
          :data="item"
        />
      </List>
    </container>
    <PageBar>
      <Button
        label="导出下载"
        theme="second"
        @click="onExcel"
      />
    </PageBar>
  </Page>
</template>

<script>
import storeMixin from '@/mixins/storeMixin';

export default {
  name: 'ManagementActivity',
  mixins: [storeMixin],
  data() {
    return {
      statList: [],
    };
  },
  computed: {
    activityId() {
      return this.$route.query.activityId || '';
    },
    shipMethod() {
      return this.$route.params.shipMethod;
    },
  },
  beforeRouteEnter(to, from, next) {
    const { shipMethod } = to.params;
    if (shipMethod === undefined) {
      next({
        ...to,
        params: {
          shipMethod: 0,
        },
      });
    }
    next();
  },
  methods: {
    getListData({
      storeActivityId = this.activityId,
      shipMethod = this.shipMethod,
      orderStatus,
    } = {}) {
      return this.$api.Buyer.Sp.ShopStoreOrder.goodsStatList({
        storeActivityId,
        orderStatus,
        shipMethod,
      }).then(json => {
        const res = json.data;
        this.statList = res.data;
        return Promise.resolve(json);
      });
    },
    onExcel({
      storeActivityId = this.activityId,
      shipMethod = this.shipMethod,
      orderStatus,
    } = {}) {
      return this.$api.Buyer.Sp.ShopStoreOrder.exportGoodsStatExcel({
        storeActivityId,
        orderStatus,
        shipMethod,
      });
    },
    onTabsChange(item) {
      this.$router.replace(
        {
          ...this.$route,
          params: {
            shipMethod: item.sign,
          },
        },
        this.getListData,
      );
    },
  },
  created() {
    this.getListData();
  },
};
</script>

<style lang="scss">
.activity-header {
  padding: $padding $padding 0 $padding;
  > .inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding;
    background-color: $color-second;
    color: #fff;
    border-top-left-radius: $padding-small;
    border-top-right-radius: $padding-small;
  }
}
</style>
