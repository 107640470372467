<!-- Created by henian.xu on 2017/10/22. -->
<template>
  <Page>
    <container ref="container">
      <XForm
        ref="form"
        :model="formModel"
        :rules="formRules"
      >
        <FormItem
          prop="stationName"
          label="站点名称"
        >
          <XInput v-model="formModel.stationName" />
        </FormItem>
        <FormItem
          prop="stationRegionId"
          label="区　　域:"
        >
          <RegionPicker
            p-id="0"
            :query-data="{type:2}"
            v-model="formModel.stationRegionId"
          />
        </FormItem>
        <FormItem
          prop="stationDetailAddress"
          label="详细地址:"
        >
          <x-input
            type="textarea"
            :auto-size="{minRows:2,maxRows:4}"
            v-model="formModel.stationDetailAddress"
            placeholder="如街道，楼层，门牌号等"
          />
        </FormItem>
        <FormItem
          prop="houseNum"
          label="门 牌 号:"
        >
          <XInput v-model="formModel.houseNum" />
        </FormItem>
        <FormItem
          prop="contactFullname"
          label="联 系 人:"
        >
          <XInput v-model="formModel.contactFullname" />
        </FormItem>
        <FormItem
          prop="contactTel"
          label="联系人电话:"
        >
          <XInput v-model="formModel.contactTel" />
        </FormItem>
        <FormItem
          prop="orderNum"
          label="排序号:"
        >
          <XInput
            type="number"
            v-model="formModel.orderNum"
          />
        </FormItem>
        <FormItem
          prop="isAvailable"
          label="是否启用:"
        >
          <Checkbox v-model="formModel.isAvailable" />
        </FormItem>
      </XForm>
    </container>
    <PageBar>
      <Button
        @click="onSubmit"
        theme="main"
        label="提交"
      />
    </PageBar>
  </Page>
</template>

<script>
import storeMixin from '@/mixins/storeMixin';
import { Buyer } from '@/api';

export default {
  name: 'StoreAddGoods',
  mixins: [storeMixin],
  data() {
    return {
      formModel: {
        id: '',
        stationName: '', //服务站名称
        stationRegionId: '', //地区ID
        stationDetailAddress: '', //详细地址
        houseNum: '', //门牌号
        contactFullname: '', //联系人姓名
        contactTel: '', //联系人电话
        orderNum: '1', //是否可用
        isAvailable: true, //是否可用
      },
      formRules: {
        stationName: {
          required: true,
          message: '自取点名称不能为空',
        },
        stationRegionId: {
          required: true,
          message: '区域不能为空',
        },
        stationDetailAddress: {
          required: true,
          message: '详细地址不能为空',
        },
        houseNum: {
          required: true,
          message: '门牌号不能为空',
        },
        contactFullname: {
          required: true,
          message: '联系人不能为空',
        },
        contactTel: {
          required: true,
          message: '联系人电话不能为空',
        },
        orderNum: {
          required: true,
          message: '排序号不能为空',
        },
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    const id = to.params.id;
    if (id) {
      Buyer.Sp.ShopStoreServiceStation.serviceStationDetail(id).then(json => {
        const res = json.data.data;
        next(vm => {
          const { formModel } = vm;
          Object.keys(formModel).forEach(key => {
            if (key === 'isAvailable') {
              formModel[key] = +res[key];
            } else {
              formModel[key] = res[key] || formModel[key];
            }
          });
        });
      });
    } else {
      next();
    }
  },
  methods: {
    buildForm() {
      const model = JSON.parse(JSON.stringify(this.formModel));
      model.isAvailable += 0;
      return model;
    },
    onSubmit() {
      this.$refs['form'].validate().then(() => {
        this.$api.Buyer.Sp.ShopStoreServiceStation.saveServiceStation(
          this.buildForm(),
        ).then(json => {
          const res = json.data;
          this.$messageBox.tips(res.msg);
          this.$router.go(-1);
        });
      });
    },
  },
};
</script>

<style lang="scss">
</style>
