<!-- Created by henian.xu on 2017/10/22. -->
<template>
  <Page>
    <container ref="container">
      <List>
        <StoreServiceStationItem
          :to="`./addServiceStation/${item.id}`"
          v-for="(item,index) in stationList"
          :key="item.id"
          :data="item"
          @del="onDel(index)"
        />
      </List>

      <infinite-loading
        ref="infinite"
        :disabled="infiniteDisabled"
        @infinite="onInfinite"
      />
    </container>
    <PageBar>
      <Button
        to="./addServiceStation"
        theme="main"
        label="新增站点"
      />
    </PageBar>
  </Page>
</template>

<script>
import storeMixin from '@/mixins/storeMixin';
import infiniteLoading from '@/mixins/infiniteLoading';

export default {
  name: 'StoreServiceStation',
  mixins: [storeMixin, infiniteLoading],
  data() {
    return {
      stationList: [],
    };
  },
  methods: {
    getListData({ currentPage, pageSize } = {}) {
      return this.$api.Buyer.Sp.ShopStoreServiceStation.serviceStationList({
        currentPage:
          currentPage || this.pagination.currentPage
            ? this.pagination.currentPage + 1
            : 1,
        pageSize,
      }).then(json => {
        const res = json.data;
        this.stationList = this.stationList.concat(res.data);
        return Promise.resolve(json);
      });
    },
    onDel(index) {
      this.stationList.splice(index, 1);
    },
  },
  activated() {
    this.infiniteDisabled = true;
    this.stationList = [];
    this.pagination = {};
    this.$nextTick(() => {
      this.infiniteDisabled = false;
    });
  },
};
</script>

<style lang="scss">
</style>
