/**
 * Created by henian.xu on 2019/3/4.
 *
 */

import pageMixin from '@/mixins/layout/page';
import { mapGetters, mapActions } from 'vuex';
import { Session } from '@/store/storage';
let storeUserName_ = Session.get('__storeUserName__') || '';

export default {
  mixins: [pageMixin],
  data() {
    return {
      storeUserName: storeUserName_,
    };
  },
  beforeRouteEnter(to, from, next) {
    const { storeUserName } = to.params;
    console.log(storeUserName);
    if (storeUserName !== storeUserName_) {
      storeUserName_ = storeUserName;
      Session.set('__storeUserName__', storeUserName);
    }
    next(vm => {
      vm.storeUserName = storeUserName;
    });
  },
  computed: {
    ...mapGetters('countInfo', { orderCount: 'storeOrderCount' }),
    isProxyStore() {
      const pathArr = this.$route.path.split('/');
      if (!pathArr || pathArr.length < 2) return false;
      return pathArr[1] === 'proxyStore';
    },
  },
  methods: {
    ...mapActions('countInfo', {
      updatedCountInfo: 'updatedStore',
    }),
  },
};
