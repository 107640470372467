<!-- Created by henian.xu on 2017/10/22. -->
<template>
  <Page>
    <container ref="container">
      <XForm
        ref="form"
        :model="formModel"
        :rules="formRules"
      >
        <FormItem
          prop="goodsName"
          label="商品名称"
        >
          <XInput v-model="formModel.goodsName" />
        </FormItem>
        <FormItem
          prop="price"
          label="商品价格"
        >
          <XInput
            type="number"
            v-model="formModel.price"
          />
        </FormItem>
        <FormItem
          prop="goodsImage"
          label="列表图"
        >
          <Upload
            :column="3"
            :max-files="6"
            v-model="formModel.goodsImage"
            action="/rpc/comm/rs/attachment/uploadFileLibrary"
          />
        </FormItem>
        <FormItem
          prop="goodsDetailImage"
          label="详情图"
        >
          <Upload
            :column="3"
            :max-files="6"
            v-model="formModel.goodsDetailImage"
            action="/rpc/comm/rs/attachment/uploadFileLibrary"
          />
        </FormItem>
      </XForm>
    </container>
    <PageBar>
      <Button
        @click="onSubmit"
        theme="main"
        label="提交"
      />
    </PageBar>
  </Page>
</template>

<script>
import storeMixin from '@/mixins/storeMixin';
import { Buyer } from '@/api';

export default {
  name: 'StoreAddGoods',
  mixins: [storeMixin],
  data() {
    return {
      formModel: {
        id: '',
        goodsName: '商品名称', //商品名称
        price: '888.88', //价格
        goodsImage: [], //列表图
        goodsDetailImage: [], //详情图
      },
      formRules: {
        goodsName: {
          required: true,
          message: '商品名称不能为空',
        }, //商品名称
        price: {
          required: true,
          message: '商品价格不能为空',
        }, //价格
        goodsImage: {
          type: 'array',
          min: 1,
          required: true,
          message: '商品列表图不能为空',
        }, //列表图
        goodsDetailImage: {
          type: 'array',
          min: 1,
          required: true,
          message: '商品详情图不能为空',
        }, //详情图
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    const goodsId = to.params.goodsId;
    if (goodsId) {
      Buyer.Sp.ShopStoreGoods.getGoodsDetailVo(goodsId).then(json => {
        const res = json.data.data;
        next(vm => {
          const { formModel } = vm;
          Object.keys(formModel).forEach(key => {
            if (
              (key === 'goodsImage' || key === 'goodsDetailImage') &&
              res[key]
            ) {
              const names = res[key].split(',');
              formModel[key] = names.map(item => {
                return {
                  name: item,
                  address: res.goodsImageBaseUrl + item,
                };
              });
            } else {
              formModel[key] = res[key] || formModel[key];
            }
          });
        });
      });
    } else {
      next();
    }
  },
  methods: {
    buildForm() {
      const model = JSON.parse(JSON.stringify(this.formModel));
      model.goodsImage = model.goodsImage
        .reduce((p, c) => (p.push(c.name), p), [])
        .join(',');
      model.goodsDetailImage = model.goodsDetailImage
        .reduce((p, c) => (p.push(c.name), p), [])
        .join(',');
      return JSON.stringify(model);
    },
    onSubmit() {
      this.$refs['form'].validate().then(() => {
        this.$api.Buyer.Sp.ShopStoreGoods.saveGoods(this.buildForm()).then(
          json => {
            const res = json.data;
            this.$messageBox.tips(res.msg);
            this.$router.go(-1);
          },
        );
      });
    },
  },
};
</script>

<style lang="scss">
</style>
