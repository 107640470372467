<!-- Created by henian.xu on 2017/10/22. -->
<template>
  <Page>
    <tabs
      class="average"
      @tabChange="onTabsChange"
    >
      <tab-panel
        label="日视图"
        :sign="1"
        :active-panel="type === 1"
      />
      <tab-panel
        label="月视图"
        :sign="2"
        :active-panel="type === 2"
      />
    </tabs>
    <div class="second-header">
      <ListItem
        v-if="type === 1"
        class="reverse bor-b"
        extra="选择日期"
      >
        <div
          class="label"
          slot="label"
        >
          <Datetime
            class="ta-r"
            format="YYYY-MM-DD"
            @input="refreshList"
            v-model="formModel.statBeginDate"
          >
            {{ formModel.statBeginDate }}
          </Datetime>
        </div>
      </ListItem>
      <ListItem
        v-if="type === 2"
        class="reverse bor-b"
        extra="选择月份"
      >
        <div
          class="label"
          slot="label"
        >
          <Datetime
            class="ta-r"
            format="YYYY-MM"
            @input="refreshList"
            v-model="formModel.statBeginDateMonth"
          >
            {{ formModel.statBeginDateMonth }}
          </Datetime>
        </div>
      </ListItem>
    </div>
    <container ref="container">
      <List>
        <StoreGoodsStatItem
          v-for="(item) in statList"
          :key="item.id"
          :data="item"
        />
      </List>

      <infinite-loading
        ref="infinite"
        :disabled="infiniteDisabled"
        @infinite="onInfinite"
      />
    </container>
  </Page>
</template>

<script>
import storeMixin from '@/mixins/storeMixin';
import infiniteLoading from '@/mixins/infiniteLoading';

export default {
  name: 'ManagementActivity',
  mixins: [storeMixin, infiniteLoading],
  data() {
    return {
      statList: [],
      formModel: {
        statBeginDate: this.$moment().format('YYYY-MM-DD'),
        statEndDate: '',
        statBeginDateMonth: this.$moment().format('YYYY-MM'),
        statEndDateMonth: '',
      },
    };
  },
  computed: {
    type() {
      return +this.$route.params.type;
    },
  },
  beforeRouteEnter(to, from, next) {
    const { type } = to.params;
    if (type === undefined) {
      next({
        ...to,
        params: {
          type: 1,
        },
      });
    }
    next();
  },
  methods: {
    getListData({ type = this.type, currentPage, pageSize } = {}) {
      const data = {
        ...this.formModel,
        statEndDate: this.formModel.statBeginDate,
        statEndDateMonth: this.formModel.statBeginDateMonth,
      };
      return this.$api.Buyer.Sp.ShopStoreStat.goodsSaleStatList({
        ...data,
        type,
        currentPage:
          currentPage || this.pagination.currentPage
            ? this.pagination.currentPage + 1
            : 1,
        pageSize,
      }).then(json => {
        const res = json.data;
        this.statList = this.statList.concat(res.data);
        return Promise.resolve(json);
      });
    },
    onTabsChange(item) {
      this.$router.replace(
        {
          ...this.$route,
          params: {
            type: item.sign,
          },
        },
        this.refreshList,
      );
    },
    refreshList() {
      this.infiniteDisabled = true;
      this.pagination = {};
      this.statList = [];
      this.$nextTick(() => {
        this.infiniteDisabled = false;
      });
    },
  },
};
</script>

<style lang="scss">
.activity-header {
  padding: $padding $padding 0 $padding;
  > .inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding;
    background-color: $color-second;
    color: #fff;
    border-top-left-radius: $padding-small;
    border-top-right-radius: $padding-small;
  }
}
</style>
