<!-- Created by henian.xu on 2017/10/22. -->
<template>
  <Page>
    <container ref="container">
      <x-form
        ref="form"
        :rules="rules"
        :model="formData"
      >
        <form-item
          class="npa-a"
          prop="shipInfo"
        >
          <ship-method
            ref="shipMethod"
            :ship-method="orderData.shipMethod"
            v-model="shipInfo"
            shop-disabled
            :disabled="!!orderId"
          />
        </form-item>
        <!--<form-item
                    class="npa-a"
                    prop="deliveryAddressId"
                >
                    <address-picker
                        ref="addressPicker"
                        :uuid="this.uuid"
                        v-model="address"
                    />
                </form-item>-->
        <form-item
          label="买家留言:"
          prop="buyerMsg"
        >
          <x-input
            type="textarea"
            :auto-size="{maxRows:4}"
            placeholder="请输入"
            v-model="formData.buyerMsg"
            :disabled="!!orderId"
          />
        </form-item>
      </x-form>
      <list class="ma-t">
        <OrderGoodsItem
          class="bc-w"
          v-for="(item) in goodsList"
          :key="item.id"
          :data="item"
        />
        <list-item
          label="商品金额"
        >
          <div
            class="extra price"
            slot="extra"
          >
            {{ goodsAmount }}
          </div>
        </list-item>
        <list-item
          v-if="freight"
          label="运费"
        >
          <div
            class="extra"
            slot="extra"
          >
            <span
              class="pa-l"
              :class="isNaN(freight) ?'tc-red': 'price'"
            >{{ freight }}</span>
          </div>
        </list-item>
      </list>
      <list class="ma-t">
        <list-item
          class="link npa-t npa-b"
        >
          <coupon-picker
            class="label"
            slot="label"
            label="优惠券"
            :order-amount="orderAmount"
            v-model="couponData"
          >
            <div
              class="extra"
              slot="extra"
            >
              <div v-if="!couponData.id && orderData.couponNum > 0">
                <div class="badge badge-red plane">
                  有 {{ orderData.couponNum }} 张可用
                </div>&nbsp;
                <span class="tc-red">去使用&nbsp;</span>
              </div>
              <div
                class="details"
                v-else-if="orderData.couponNum === 0"
              >
                无可用
              </div>
              <div v-else>
                - <span class="price">{{ couponData.couponAmount }}</span>
              </div>
            </div>
          </coupon-picker>
        </list-item>
        <!-- 积分模块-->
        <template
          v-if="pointCase.isAvailable"
        >
          <template
            v-if="orderTotalAmount >= pointCase.minOrderAmount"
          >
            <list-item>
              <div
                class="label"
                slot="label"
              >
                共 {{ pointCase.validPoint }} 积分, 可用 {{ maxAvailablePoint }},抵 {{ maxAvailablePoint * this.pointProportion }} 元
              </div>
              <div
                class="extra"
                slot="extra"
              >
                <x-switch v-model="isUserPoint" />
              </div>
            </list-item>
            <list-item
              v-show="isUserPoint"
              :label="`${pointCase.consumptionPoints} 积分 = ${pointCase.deductibleAmount}`"
            >
              <div
                class="extra"
                slot="extra"
              >
                - <span class="price">{{ pointQuantity * pointProportion }}</span>
                <i
                  class="f-icon tc-red ma-r"
                  @click="onPointDesc"
                >&#xf043;</i>
                <input-number
                  no-input
                  :min="0"
                  :max="maxAvailablePoint"
                  :step="pointCase.consumptionPoints"
                  v-model="pointQuantity"
                />
              </div>
            </list-item>
          </template>
          <list-item
            v-else
          >
            <div
              class="label"
              slot="label"
            >
              共 {{ pointCase.validPoint }} 积分, 本单小于 <span class="tc-red">${{ pointCase.minOrderAmount | price }}</span> 不可用
            </div>
            <div
              class="extra"
              slot="extra"
            >
              <i
                class="f-icon tc-red ma-l"
                @click="onPointDesc"
              >&#xf043;</i>
            </div>
          </list-item>
        </template>
      </list>
    </container>
    <div
      class="second-footer free-freight"
      v-if="freeFreight"
    >
      本次订单在购 <span class="tc-red">{{ freeFreight }}</span> 商品免运费
    </div>
    <div class="footer new-order">
      <div class="total-amount">
        合计：<span class="fs-big price">{{ totalAmount }}</span>
      </div>
      <div
        class="btn btn-main"
        @click="onSubmit"
      >
        提交订单
      </div>
      <pay-popup
        ref="payPopup"
        :order-ids="orderId"
        :module="1"
      />
    </div>
    <!--<x-dialog
            ref="freightDialog"
            label="抱歉，以下商品无该地址配送服务"
            bodyContent="body"
        >
            <list>
                <OrderGoodsItem
                    class="bc-w"
                    v-for="(item,index) in unsupportedAreaGoodsList"
                    :key="item.id"
                    :data="item"
                />
            </list>
            <div class="footer bor-t" slot="footer">
                <div class="btn"
                     @click="onSelectAddress"
                >选择地址
                </div>
                <div class="btn btn-red"
                     @click="onCancelOrder"
                >取消
                </div>
            </div>
        </x-dialog>-->
  </Page>
</template>

<script>
import pageMixin from '@/mixins/layout/page';
import storeMixin from './storeMixin';
import { Buyer } from '@/api';

export default {
  name: 'OrderNew',
  mixins: [pageMixin, storeMixin],
  data() {
    return {
      shipInfo: {},
      freightData: {
        // freightAmount：运费金额
        // freightMode: 1:全场包邮；2：固定运费；3：使用运费模板
        // unsupportedAreaGoodsIdList 不满足条件的商品id
      },
      orderData: {},
      goodsList: [],
      goodsAmount: 0,
      orderAmount: 0,
      goodsDiscountAmount: 0,
      orderType: 0,
      pointCase: {},
      couponData: {},
      isUserPoint: false,
      pointQuantity: 0,
      formData: {
        shipInfo: '',
        buyerMsg: '',
      },
      rules: {
        shipInfo: {
          validator: this.validShipInfo,
          // message: '请选择收货地址',
        },
      },
      orderId: 0, // 生成订单后返回的订单 id
    };
  },
  computed: {
    uuid() {
      return this.$route.params.uuid;
    },
    $$shipMethod() {
      return this.$refs.shipMethod || null;
    },
    $$addressPicker() {
      return this.$$shipMethod.$$addressPicker || null;
    },
    $$freightDialog() {
      return this.$refs.freightDialog || null;
    },
    $$payPopup() {
      return this.$refs.payPopup || null;
    },
    address() {
      return this.shipInfo.address || {};
    },
    shipType() {
      return this.shipInfo.shipType || 0;
    },
    goodsMap() {
      const res = {};
      this.goodsList.forEach(item => {
        res[item.goodsId] = item;
      });
      return res;
    },
    unsupportedAreaGoodsList() {
      const unGoodsIds = this.freightData.unsupportedAreaGoodsIdList;
      if (!unGoodsIds || !unGoodsIds.length) return [];
      const res = [];
      unGoodsIds.forEach(item => {
        res.push(this.goodsMap[item]);
      });
      return res;
    },
    freeFreight() {
      const { freightData } = this;
      if (!this.address.id || this.shipType !== 1) return 0;
      const mode = freightData.freightMode;
      if (mode !== 2) return 0;
      const res = freightData.freeFreightOrderAmount - this.goodsAmount;
      return res <= 0 ? 0 : res;
    },
    freight() {
      const { freightData } = this;
      if (!this.address.id || this.shipType !== 1) return '';
      const mode = freightData.freightMode;
      const freight = freightData.freightAmount;
      // const freeFreightOrderAmount = this.address.freeFreightOrderAmount;
      switch (mode) {
        case 1:
          return '全场包邮';
        case 2:
        case 3:
          return !+freight ? '免运费' : +freight === -1 ? '无法配送' : freight;
        default:
          return '';
      }
    },
    totalAmount() {
      const isUserPoint =
        this.pointCase.isAvailable &&
        this.isUserPoint &&
        this.orderTotalAmount >= this.pointCase.minOrderAmount;
      return isUserPoint
        ? this.orderTotalAmount - this.pointQuantity * this.pointProportion
        : this.orderTotalAmount;
    },
    /**
     * 订单合计 = 订单金额 + 运费 - 优惠券
     * @returns {number}
     */
    orderTotalAmount() {
      const freight = isNaN(this.freight) ? 0 : this.freight;
      return (
        this.orderAmount +
        freight -
        (this.couponData.id ? this.couponData.couponAmount : 0)
      );
    },
    /**
     * 积分/金额
     */
    pointProportion() {
      const {
        consumptionPoints, // 消耗积分
        deductibleAmount, // 抵扣金额
      } = this.pointCase;
      return deductibleAmount / consumptionPoints;
    },
    /**
     * 最大可用积分 = min(有效积分, parseInt(订单合计 * 最大比率 / 抵扣金额) * 消耗积分)
     */
    maxAvailablePoint() {
      const {
        validPoint, // 有效积分
        consumptionPoints, // 消耗积分
        deductibleAmount, // 抵扣金额
        maxProportion, // 最大比率
      } = this.pointCase;
      const {
        orderTotalAmount, // 订单合计
      } = this;

      return Math.min(
        validPoint,
        parseInt((orderTotalAmount * maxProportion) / deductibleAmount + '') *
          consumptionPoints,
      );
    },
  },
  watch: {
    shipInfo(val, oldVal) {
      if (val === oldVal) return;
      this.$$form.clearValidate();
      this.formData.shipInfo = val;
    },
    address(val, oldVal) {
      if (val === oldVal) return;
      if (val.id) {
        this.formData.deliveryAddressId = val.id;
        this.$nprogress.start();
        this.$api.Buyer.Sp.ShopStoreFreight.getOrderFreightInfo({
          storeId: this.storeId,
          uuid: this.uuid,
          deliveryAddressId: val.id,
        }).then(json => {
          this.$nprogress.done();
          this.freightData = json.data.data;
          this.validFreightData();
        });
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    Buyer.Sp.ShopStoreOrder.cartConfirm({
      storeId: to.params.storeId,
      uuid: to.params.uuid,
    })
      .then(json => {
        const res = json.data.data;
        next(vm => {
          vm.orderData = res;
          vm.$set(vm.shipInfo, 'address', res.address || {});
          vm.$set(vm.shipInfo, 'shop', res.shopStore || {});
          vm.goodsList = res.goodsList;
          vm.goodsAmount = res.goodsAmount;
          vm.orderAmount = res.orderAmount;
          vm.pointCase = res.pointCase;
          vm.goodsDiscountAmount = res.goodsDiscountAmount;
          vm.orderType = res.orderType;
        });
      })
      .catch(() => next());
  },
  methods: {
    validShipInfo(rule, value, callback) {
      const { shipType, address, shop } = value;
      if (shipType === 1 && (!address || !address.id)) {
        callback(new Error('请选择收货地址'));
      } else if (shipType === 2 && (!shop || !shop.id)) {
        callback(new Error('请选择自取门店'));
      }
      callback();
    },
    validFreightData() {
      if (this.freightData.freightAmount === -1) {
        this.$messageBox
          .alert(this.freightData.errorMsg, '温馨提示', {
            // cancelBtnText: '取消',
            // confirmBtnText: '重新选择地址',
          })
          .then(() => {
            // this.onSelectAddress();
          });
        return false;
      }
      return true;
    },
    onSubmit() {
      const { shipInfo } = this;
      const {
        shipType,
        address,
        shop,
        pickupDate,
        storeServiceTimeId,
      } = shipInfo;
      if (+shipType === 1 && !this.validFreightData()) return;

      this.$$form.validate().then(() => {
        const data = {
          ...this.formData,
          storeId: this.storeId,
          uuid: this.uuid,
        };
        data.shipMethod = shipType;
        if (shipType === 1) {
          if (!address || !address.id) {
            this.$messageBox.tips('请选择收货地址');
            return;
          }
        } else if (shipType === 2) {
          if (!shop || !shop.id) {
            this.$messageBox.tips('请选择门店');
            return;
          }
          if (!pickupDate) {
            this.$messageBox.tips('请选择取货日期');
            return;
          }
          if (!storeServiceTimeId) {
            this.$messageBox.tips('请选择取货时间');
            return;
          }
          data.pickupDate = pickupDate;
          data.storeServiceTimeId = storeServiceTimeId;
        }

        if (this.isUserPoint && this.pointQuantity) {
          data.usePoint = this.pointQuantity;
        }
        if (this.couponData.id) {
          data.couponId = this.couponData.id;
        }
        if (this.orderId) {
          this.onPay(this.orderId);
        } else {
          this.$api.Buyer.Sp.ShopStoreOrder.createOrder(data).then(json => {
            // const res = json.data;
            this.orderId = json.data.data;
            this.onPay(this.orderId);
            this.clearCarts();
            /* this.$messageBox.confirm('订单已生成是否立即支付。', '温馨提示', {
                            cancelBtnText: '继续购买',
                            confirmBtnText: '立即支付',
                        }).then((action) => {
                            this.onPay(res.data);
                        }).catch((_err) => {
                            this.$router.go(-1);
                        }); */
          });
        }
      });
    },
    onPay() {
      this.$$payPopup.open();
    },
    onPointDesc() {
      this.$messageBox.alert(
        `<div class="ta-l">
                                            <div class="fw-bold">使用条件</div>
                                            <p>1.订单金额大于 ${
                                              this.currency
                                            } ${
          this.pointCase.minOrderAmount
        }（含）；</p>
                                            <p>2.积分数量大于 ${
                                              this.pointCase.consumptionPoints
                                            }（含）；</p>
                                            <p>3.积分支付不得超过每笔订单应付金额的 ${this
                                              .pointCase.maxProportion *
                                              100}%；</p>
                                            <br>
                                            <div class="fw-bold">使用数量</div>
                                            <p>1.使用积分数量为 ${
                                              this.pointCase.consumptionPoints
                                            } 的整数倍；</p>
                                            <p>2.${
                                              this.pointCase.consumptionPoints
                                            } 积分可抵 ${this.currency} ${
          this.pointCase.deductibleAmount
        }；</p>
                                        </div>`,
        '积分使用规则',
      );
    },
    onSelectAddress() {
      // this.$$freightDialog.done().then(() => {
      //     console.log(this.$$addressPicker);
      // });
      this.$$addressPicker.open();
    },
    onCancelOrder() {
      // this.$$freightDialog.done().then(() => {
      // this.$router.go(-1);
      // });
    },
  },
};
</script>

<style lang="scss">
.footer.new-order {
  background-color: #fff;
  > .total-amount {
    flex: 2 2 1%;
    text-align: right;
    padding-right: $padding-small;
  }
}

.free-freight {
  text-align: center;
  min-height: inherit !important;
  padding: $padding-small;
  background-color: mix($color-red, #fff, 40%);
  color: #fff;
}
</style>
