<!-- Created by henian.xu on 2017/10/22. -->
<template>
  <Page :title="storeInfo.storeName||pageData.title">
    <container
      ref="container"
      @scroll="onContainerScroll"
    >
      <ShopHeader
        class="ma-bor-b"
      />
      <classify
        style-type="tabs"
        :no-scroll="noScroll"
      >
        <classify-panel
          v-for="(item) in goodsCatList"
          :key="item.id"
          :label="item.goodsCatName"
        >
          <list class="goods">
            <StoreGoodsItem
              v-for="(goods) in item.goodsList"
              :key="goods.id"
              :data="goods"
              double-row
            />
          </list>
        </classify-panel>
      </classify>
    </container>
  </Page>
</template>

<script>
import pageMixin from '@/mixins/layout/page';
import storeMixin from './storeMixin';
import StoreGoodsItem from './_storeGoodsItem';
import ShopHeader from './_shopHeader';

export default {
  name: 'StoreHome',
  mixins: [pageMixin, storeMixin],
  components: { StoreGoodsItem, ShopHeader },
  data() {
    return {
      noScroll: true,
    };
  },
  watch: {
    storeId: {
      handler(val) {
        if (!val) return;
        this.getGoodsCatList();
      },
      immediate: true,
    },
  },
  methods: {
    onContainerScroll($event) {
      const { target } = $event;
      const { scrollTop, clientHeight, scrollHeight } = target;
      this.noScroll = scrollTop + clientHeight < scrollHeight;
      // console.log(scrollTop, clientHeight, scrollHeight);
    },
  },
};
</script>

<style lang="scss">
</style>
