<!-- Created by henian.xu on 2017/10/22. -->
<template>
  <Page>
    <tabs
      class="average"
      @tabChange="onTabsChange"
    >
      <tab-panel
        label="日视图"
        :sign="1"
        :active-panel="+type === 1"
      />
      <tab-panel
        label="月视图"
        :sign="2"
        :active-panel="+type === 2"
      />
    </tabs>
    <container ref="container">
      <List>
        <list-item
          v-for="item in statList"
          :key="item.statDate"
          :label="item.statDate"
          :extra="item.payableAmount"
        >
          <div
            class="extra"
            slot="extra"
          >
            <div class="price">
              {{ item.payableAmount }}
            </div>
            <div class="fs-small">
              订单数: x {{ item.orderNum }}
            </div>
          </div>
        </list-item>
      </List>

      <infinite-loading
        ref="infinite"
        :disabled="infiniteDisabled"
        @infinite="onInfinite"
      />
    </container>
  </Page>
</template>

<script>
import storeMixin from '@/mixins/storeMixin';
import infiniteLoading from '@/mixins/infiniteLoading';

export default {
  name: 'ManagementActivity',
  mixins: [storeMixin, infiniteLoading],
  data() {
    return {
      statList: [],
    };
  },
  computed: {
    type() {
      return this.$route.params.type;
    },
  },
  beforeRouteEnter(to, from, next) {
    const { type } = to.params;
    if (type === undefined) {
      next({
        ...to,
        params: {
          type: 1,
        },
      });
    }
    next();
  },
  methods: {
    getListData({ type = this.type, currentPage, pageSize } = {}) {
      return this.$api.Buyer.Sp.ShopStoreStat.orderSaleStatList({
        type,
        currentPage:
          currentPage || this.pagination.currentPage
            ? this.pagination.currentPage + 1
            : 1,
        pageSize,
      }).then(json => {
        const res = json.data;
        this.statList = this.statList.concat(res.data);
        return Promise.resolve(json);
      });
    },
    onTabsChange(item) {
      this.$router.replace(
        {
          ...this.$route,
          params: {
            type: item.sign,
          },
        },
        this.refreshList,
      );
    },
    refreshList() {
      this.infiniteDisabled = true;
      this.pagination = {};
      this.statList = [];
      this.$nextTick(() => {
        this.infiniteDisabled = false;
      });
    },
  },
};
</script>

<style lang="scss">
</style>
