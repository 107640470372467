<!-- Created by henian.xu on 2019/3/4. -->

<template>
  <Page>
    <Container ref="container">
      <!--<CubeToolbar class="small ma-b">
                <linker
                    to="/order/list/20"
                    class="item">
                    <i class="f-icon">
                        &#xf051;
                        <em>99</em>
                    </i>
                    <div class="label">待发货</div>
                </linker>
                <linker
                    to="/order/list/30"
                    class="item">
                    <i class="f-icon">
                        &#xf052;
                        <em>99</em>
                    </i>
                    <div class="label">已发货</div>
                </linker>
                <linker
                    to="/order/list/30"
                    class="item">
                    <i class="f-icon">
                        &#xf053;
                        <em>99</em>
                    </i>
                    <div class="label">已完成</div>
                </linker>
            </CubeToolbar>-->
      <StoreHeader :data="storeDetail" />

      <div class="tool-bar ma-bor-b">
        <div class="item">
          <div class="num">
            {{ 888 }}
          </div>
          <div class="label">
            销售订单数
          </div>
        </div>
        <div class="item">
          <div class="num price">
            {{ 666 | price }}
          </div>
          <div class="label">
            销售总额
          </div>
        </div>
      </div>

      <List class="ma-b">
        <list-item
          to="/distributor/percentage/recommend"
          class="link"
          icon-class-name="tc-blue"
          icon="&#xf028;"
        >
          <div
            class="label lh-normal"
            slot="label"
          >
            <div>扫码消单</div>
            <div class="fs-small tc-g6">
              扫描二维码完成门店订单与商城订单的提货操作
            </div>
          </div>
        </list-item>
        <!--<list-item
                    to="/distributor/percentage/recommend"
                    class="link"
                    icon-class-name="tc-blue"
                    icon="&#xf089;"
                >
                    <div
                        class="label lh-normal"
                        slot="label">
                        <div>店铺信息管理</div>
                        <div class="fs-small tc-g6">维护店铺名称信息等</div>
                    </div>
                </list-item>-->
      </List>
      <List class="ma-b">
        <list-item
          to="./goods"
          class="link"
          icon-class-name="tc-blue"
          icon="&#xf01c;"
        >
          <div
            class="label lh-normal"
            slot="label"
          >
            <div>商品管理</div>
            <div class="fs-small tc-g6">
              可维护商品对应类目，商品库存，排序信息
            </div>
          </div>
        </list-item>
        <list-item
          to="./serviceStation"
          class="link"
          icon-class-name="tc-blue"
          icon="&#xf089;"
        >
          <div
            class="label lh-normal"
            slot="label"
          >
            <div>自取点管理</div>
            <div class="fs-small tc-g6">
              可维护商品对应类目，商品库存，排序信息
            </div>
          </div>
        </list-item>
        <list-item
          to="./activity"
          class="link"
          icon-class-name="tc-blue"
          icon="&#xf01d;"
        >
          <div
            class="label lh-normal"
            slot="label"
          >
            <div>活动管理</div>
            <div class="fs-small tc-g6">
              可维护商品对应类目，商品库存，排序信息
            </div>
          </div>
        </list-item>
      </List>
      <List class="ma-b">
        <list-item
          :to="`./deliveryOrder/1`"
          class="link"
          icon-class-name="tc-blue"
          icon="&#xf053;"
        >
          <div
            class="label lh-normal"
            slot="label"
          >
            <div>配送订单</div>
            <div class="fs-small tc-g6">
              可维护商品对应类目，商品库存，排序信息
            </div>
          </div>
        </list-item>
        <list-item
          :to="`./deliveryOrder/2`"
          class="link"
          icon-class-name="tc-blue"
          icon="&#xf055;"
        >
          <div
            class="label lh-normal"
            slot="label"
          >
            <div>自取订单</div>
            <div class="fs-small tc-g6">
              可维护商品对应类目，商品库存，排序信息
            </div>
          </div>
        </list-item>
      </List>
      <!-- 统计报表 -->
      <List class="ma-b">
        <list-item
          to="./goodsSaleStat"
          class="link"
          icon-class-name="tc-blue"
          icon="&#xf06c;"
        >
          <div
            class="label lh-normal"
            slot="label"
          >
            <div>商品统计</div>
            <div class="fs-small tc-g6">
              可维护商品对应类目，商品库存，排序信息
            </div>
          </div>
        </list-item>
        <list-item
          to="./saleStat"
          class="link"
          icon-class-name="tc-blue"
          icon="&#xf06a;"
        >
          <div
            class="label lh-normal"
            slot="label"
          >
            <div>销量统计</div>
            <div class="fs-small tc-g6">
              可维护商品对应类目，商品库存，排序信息
            </div>
          </div>
        </list-item>
        <!--<list-item
                    to="/distributor/percentage/recommend"
                    class="link"
                    icon-class-name="tc-blue"
                    icon="&#xf06b;"
                >
                    <div
                        class="label lh-normal"
                        slot="label">
                        <div>财务统计</div>
                        <div class="fs-small tc-g6">可维护商品对应类目，商品库存，排序信息</div>
                    </div>
                </list-item>-->
      </List>
      <!-- 我的客户 -->
      <List class="ma-b">
        <list-item
          to="./buyerStat"
          class="link"
          icon-class-name="tc-blue"
          icon="&#xf022;"
        >
          <div
            class="label lh-normal"
            slot="label"
          >
            <div>我的客户</div>
            <div class="fs-small tc-g6">
              可维护商品对应类目，商品库存，排序信息
            </div>
          </div>
        </list-item>
      </List>
    </Container>
  </Page>
</template>

<script>
import storeMixin from '@/mixins/storeMixin';
import { Buyer } from '@/api';

export default {
  name: 'StoreHome',
  mixins: [storeMixin],
  data() {
    return {
      storeDetail: {},
    };
  },
  beforeRouteEnter(to, from, next) {
    Buyer.Sp.ShopStore.getShopStoreDetail()
      .then(json => {
        const res = json.data.data;
        next(vm => {
          vm.storeDetail = res;
          console.log(res);
        });
      })
      .catch(() => next());
  },
};
</script>

<style lang="scss">
</style>
