<!-- Created by henian.xu on 2017/10/22. -->
<template>
  <Page>
    <container ref="container">
      <div class="activity-header">
        <div class="inner">
          <div class="label">
            {{ storeActivity.activityName }}
          </div>
          <span :class="['badge',storeActivity.activityStatus===1?'badge-red':'badge-g5']">{{ storeActivity.activityStatusName }}</span>
        </div>
      </div>
      <List>
        <ListItem
          label="开始时间"
          :extra="storeActivity.activityBeginTime|moment('YYYY-MM-DD HH:mm:ss')"
        />
        <ListItem
          label="截止时间"
          :extra="storeActivity.activityEndTime|moment('YYYY-MM-DD HH:mm:ss')"
        />
        <ListItem label="送货方式">
          <div
            class="extra"
            slot="extra"
          >
            <Selector
              multiple
              bitwise
              tiling
              readonly
              v-model="storeActivity.shipMethod"
              :data="shipMethodList"
            />
          </div>
        </ListItem>
        <ListItem label="付款类型">
          <div
            class="extra"
            slot="extra"
          >
            <Selector
              multiple
              bitwise
              tiling
              readonly
              v-model="storeActivity.paymentType"
              :data="paymentTypeList"
            />
          </div>
        </ListItem>
        <ListItem
          label="是否可用"
        >
          <div
            class="extra"
            slot="extra"
          >
            <span
              v-if="storeActivity.isAvailable"
              class="badge badge-main"
            >是</span>
            <span
              v-else
              class="badge badge-g5"
            >否</span>
          </div>
        </ListItem>
      </List>

      <div
        class="pa-a"
        v-if="storeActivity.activityStatus===1"
      >
        <Button
          label="手工截止活动"
          theme="second"
          class="w-100per"
          radius="all"
          @click="onCloseActivity"
        />
      </div>
    </container>
    <PageBar>
      <Button
        label="编辑"
        theme="second"
        :to="`../addActivity/${activityId}`"
      />
      <Button
        label="复制活动"
        theme="main"
        :to="`../addActivity/${activityId}?copy=1`"
      />
      <Button
        label="订单管理"
        theme="blue"
        :to="`../orderList/${activityId}`"
      />
    </PageBar>
  </Page>
</template>

<script>
import storeMixin from '@/mixins/storeMixin';
import { Buyer } from '@/api';

export default {
  name: 'ManagementActivity',
  mixins: [storeMixin],
  data() {
    return {
      data: {},
      shipMethodList: [],
      paymentTypeList: [],
      serviceStation: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    const id = to.params.id;

    const requestList = [
      Buyer.Sp.ShopStore.getStoreShipMethodList(),
      Buyer.Sp.ShopStore.getStorePaymentTypeList(),
      Buyer.Sp.ShopStoreServiceStation.getServiceStationList(),
      Buyer.Sp.ShopStoreActivity.shopStoreActivityDetail(id),
    ];
    Promise.all(requestList)
      .then(jsons => {
        next(vm => {
          vm.shipMethodList = jsons[0].data.data;
          vm.paymentTypeList = jsons[1].data.data;
          vm.serviceStation = jsons[2].data.data;
          vm.data = jsons[3].data.data;
        });
      })
      .catch(() => next());
  },
  computed: {
    storeActivity() {
      return this.data.storeActivity || {};
    },
    activityId() {
      return this.$route.params.id;
    },
  },
  methods: {
    getData() {
      Buyer.Sp.ShopStoreActivity.shopStoreActivityDetail(this.activityId).then(
        json => {
          const res = json.data;
          this.data = res.data;
        },
      );
    },
    onCloseActivity() {
      this.$messageBox.confirm('是否立即截止活动').then(() => {
        this.$api.Buyer.Sp.ShopStoreActivity.closeShopStoreActivity(
          this.activityId,
        ).then(json => {
          const res = json.data;
          this.getData();
          console.log(res);
        });
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss">
.activity-header {
  padding: $padding $padding 0 $padding;
  > .inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding;
    background-color: $color-second;
    color: #fff;
    border-top-left-radius: $padding-small;
    border-top-right-radius: $padding-small;
  }
}
</style>
